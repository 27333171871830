




















import { Component, Vue } from "vue-property-decorator";
import CustomFormDesignEditor from "@/components/CustomForm/Design/index.vue";
import { CustomFormType } from "@/api/appService";
import api from "@/api";

@Component({
  components: { CustomFormEditor: CustomFormDesignEditor },
})
export default class CustomFormDesign extends Vue {
  private hostType: CustomFormType = CustomFormType.InternalProject;
  private hostId = "";
  private formId = "";

  private isRouteParamReady = false;

  private isSubmitting = false;


  created() {
    this.hostType = this.$route.query.hostType as CustomFormType;
    if (this.$route.query.formId) {
      this.formId = this.$route.query.formId as string;
      this.isRouteParamReady = true;
    } else {
      this.hostId = this.$route.query.hostId as string;



      if (!this.hostType) {
        this.$message.error("HostType 参数是必须的,请检查url参数");
        return;
      }
      api.customFormService.getCustomFormId({hostType:this.hostType,hostId: this.hostId}).then(formId=>{
        if(formId){
          this.formId = formId;
        }
        this.isRouteParamReady = true;
      })
    }
  }

  async handleSave() {
    await (this.$refs.customFormEditor as any).save();
  }
}
