













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "FormControl" })
export default class FormControl extends Vue {
  @Prop({ required: true })
  name!: string;

  @Prop({ required: true })
  icon!: string;

  @Prop({ required: true })
  text!: string;

  @Prop({ required: false, default: "" })
  defaultText!: string;

  @Prop({ required: false, default: false })
  required!: boolean;
}
