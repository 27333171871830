import { CustomFormFieldCreateOrUpdateDto } from "@/api/appService";

import AbstractControlEditor from "./AbstractControlEditor";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";

export default class TextControlEditor extends AbstractControlEditor {
  public placeholder!: ElementProperty;

  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);

    this.placeholder = new ElementProperty("输入提示", undefined, field);
    this.placeholder.propertyValue = field.placeholder;

    this.placeholder.onPropertyValueChange = (newValue) => {
      this.field.placeholder = newValue;
    };
  }

  getProperties(): ElementProperty[] {
    return [...super.getProperties(), this.placeholder];
  }
}
