



















































import {Component, Prop, Vue} from "vue-property-decorator";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {newGuid, PropertyValueEditorType,} from "@/components/CustomForm/ControlEditor/common";
import FormControlValidatorPropertyEditor from "@/components/CustomForm/Design/FormControlValidatorPropertyEditor.vue";
import TableColumnsPropertyEditor from "@/components/CustomForm/Design/TableEditor/TableColumnsPropertyEditor.vue";

@Component({
  name: "FormControlPropertyEditor",
  components: {TableColumnsPropertyEditor, FormControlValidatorPropertyEditor },
})
export default class FormControlPropertyEditor extends Vue {
  @Prop({ required: true })
  property!: ElementProperty;

  get isGroup() {
    return this.property.valueEditor === PropertyValueEditorType.Group;
  }

  get isTable(){
    return this.property.valueEditor === PropertyValueEditorType.Table
  }

  get isInput() {
    return this.property.valueEditor === PropertyValueEditorType.Input;
  }

  get isSelect() {
    return this.property.valueEditor === PropertyValueEditorType.Dropdown;
  }

  get isValidator() {
    return this.property.valueEditor === PropertyValueEditorType.Validators;
  }

  get isRadio() {
    return this.property.valueEditor === PropertyValueEditorType.Radio;
  }

  get isCustomValues() {
    return this.property.valueEditor === PropertyValueEditorType.CustomValues;
  }

  get isSwitch() {
    return this.property.valueEditor === PropertyValueEditorType.Switch;
  }

  get hasError() {
    return this.property.valueCheckErrors.length > 0;
  }

  handleAddNewCustomValue() {
    console.log("addNewCustomValue", this.property.formControl!.id);
    console.log("addNewCustomValue", this.property.formControl!.chooseValues);
    let item = { id: newGuid(), displayName: "" };
    this.property.formControl!.chooseValues!.push(item);
    // this.$set(this.property.formControl!.chooseValues!,'elementAttributes',[{id:newGuid(),displayName:''}])
    this.$forceUpdate();
    // this.property.formControl!.chooseValues! = [{id:newGuid(),displayName:''}]
  }

  handleRemoveCustomValue(index: number) {
    this.property.formControl!.chooseValues!.splice(index, 1);
  }
}
