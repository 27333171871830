import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {PropertyValueEditorType} from "@/components/CustomForm/ControlEditor/common";

export default class ElementPropertyFactory {
  static createForName(field: CustomFormFieldCreateOrUpdateDto): ElementProperty {
    const property = new ElementProperty('键名', undefined, field)
    property.isPropertyValueRequired = true;
    property.isPropertyValueCheckOnRealTime = true;
    if (!field.isDynamic) {
      property.readonly = true;
    }
    property.propertyValue = field.fieldName;
    property.propertyValueValidate = (value) => {
      const result: string[] = [];
      if (!/^([a-zA-Z]{1}[\d\w_]{0,19})$/.test(value!)) {
        result.push("键名必须是字母开头，且总长度不能超过20个字符");
      }
      return result;
    }

    property.onPropertyValueChange = (newValue) => {
      field.fieldName = newValue
    }

    return property;

  }


  static createForTitle(field: CustomFormFieldCreateOrUpdateDto): ElementProperty {
    const formLabel = new ElementProperty("标题", undefined, field);
    if (!field.isDynamic) {
      formLabel.readonly = false;
    }
    formLabel.isPropertyValueRequired = true;
    formLabel.propertyValue = field.fieldDisplayName;
    formLabel.onPropertyValueChange = (newValue) => {
      field.fieldDisplayName = newValue;
    };

    return formLabel
  }

  static createForRequired(field: CustomFormFieldCreateOrUpdateDto): ElementProperty {
    const required = new ElementProperty("必填项", undefined, field);
    required.valueEditor = PropertyValueEditorType.Switch;
    required.isPropertyValueRequired = true;
    required.propertyValue = (field.required ?? false)
      .toString()
      .toLowerCase();
    required.onPropertyValueChange = (newValue) => {
      field.required = newValue === "true";
    };

    return required
  }

  static createGroup(groupName:string):ElementProperty {
    const group = new ElementProperty(groupName)
    group.valueEditor = PropertyValueEditorType.Group;
    group.isPropertyValueRequired = false
    group.children = []
    return group
  }


}
