


















import {Component, Prop, Vue} from "vue-property-decorator";
import {ILayoutItem} from "@/components/CustomForm/common";
import FormItem from "@/components/CustomForm/Design/FormItem.vue";

@Component({
  name:"CustomFormPreview",
  components:{FormItem}
})
export default class CustomFormPreview extends Vue{


  layoutDetails:ILayoutItem[] = [];


  show = false;

  handleClose(){
    this.show = false;
  }

}
