import AbstractControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractControlEditor";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import { CustomFormFieldCreateOrUpdateDto } from "@/api/appService";
import AbstractSelectControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractSelectControlEditor";

export default class CheckboxControlEditor extends AbstractSelectControlEditor {
  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
  }
}
