export type ValidatorCallback = (error?: Error) => void;
export  type requiredCondition = () => true;
export type Validator = (rule:ValidatorRule, value:string | number | undefined | Date,callback:ValidatorCallback) => void;
export type CompareToVariable = () => number | string | Date | undefined

export interface ValidatorRule {
  message?: string
  type?: string
  requiredCondition?: boolean | requiredCondition,
  min?: number | CompareToVariable,
  max?: number | CompareToVariable,
  greaterThan?: number | CompareToVariable
  lessThan?: number | CompareToVariable,
  validator?:Validator,


}




export const mustGreaterThanTo0 = (rule: ValidatorRule, value: string, callback: ValidatorCallback) => {
  try {
    const number = tryParseNumber(rule, value);
    if (number === undefined || number > 0) {
      callback();
    } else {
      callback(new Error(rule.message ?? "数值必须>=0"));
    }
  } catch (err) {
    callback(err);
  }
};
export const mustGreaterThanOrEqualTo0 = (rule: ValidatorRule, value: string, callback: ValidatorCallback) => {
  try {
    const number = tryParseNumber(rule, value);
    if (number === undefined || number >= 0) {
      callback();
    } else {
      callback(new Error(rule.message ?? "数值必须>=0"));
    }
  } catch (err) {
    callback(err);
  }
};

export const range = (rule: ValidatorRule, value: string, callback: ValidatorCallback) => {
  if (rule.min === undefined && rule.greaterThan === undefined) {
    throw new Error("使用range检验时，必须设置rule的min或greaterThan的值");
  }
  if (rule.max === undefined && rule.lessThan === undefined) {
    throw new Error("使用range检验时，必须设置rule的max或lessThan的值");
  }
  try {
    const number = tryParseNumber(rule, value);
    if(number === undefined){
      callback()
    } else if( rule.min && number < getCompareToValue(rule.min)){
      callback(new Error(`数值不能小于${(getCompareToValue(rule.min))}`))
    } else if(rule.greaterThan  && !(number>(getCompareToValue(rule.greaterThan)))) {
      callback(new Error(`数值必须大于${getCompareToValue(rule.greaterThan)}`))
    } else if(rule.max && number > getCompareToValue(rule.max)){
      callback(new Error(`数值不能大于${getCompareToValue(rule.max)}`))
    } else if(rule.lessThan && !(number < getCompareToValue(rule.lessThan))) {
      callback(new Error(`数值必须小于${getCompareToValue(rule.lessThan)}`))
    } else {
      callback()
    }


  } catch (err) {
    callback(err);
  }

};


export const required = (rule: ValidatorRule, value: string, callback: ValidatorCallback) => {
  if (typeof rule.requiredCondition !== "function")
    throw new Error("requiredCondition必须是函数类型");

  console.log("validator:requiredCondition =>", rule.requiredCondition(), value);
  if(rule.requiredCondition()){
    if(value){
      callback()
    } else {
      callback(new Error(rule.message ? rule.message : "该项为必填项"));
    }
  } else {
    callback()
  }

};


const tryParseNumber = (rule: ValidatorRule, value?: string): number | undefined => {
  if (value) {
    const number = parseFloat(value);
    if (isNaN(number)) {
      throw new Error("请输入一个有效的数值");
    }
    return number;
  } else {
    if (rule.requiredCondition) {
      throw new Error("请输入一个有效的数值");
    } else {
      return undefined;
    }
  }
};

const getCompareToValue = (number: number | CompareToVariable): number => {
  if(typeof number === 'number'){
    return number
  } else {
    const result = number() as number
    console.log(`getCompareToValue:${result}`)
    return result;
  }
}
