













import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TableColumnItemPropertyEditor, {
  TableField
} from "@/components/CustomForm/Design/TableEditor/TableColumnItemPropertyEditor.vue";
import {newGuid} from "@/components/CustomForm/ControlEditor/common";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";


@Component({
  name: 'TableColumnsPropertyEditor',
  components: {TableColumnItemPropertyEditor}
})
export default class TableColumnsPropertyEditor extends Vue {

  @Prop()formControl!:CustomFormFieldCreateOrUpdateDto

  fields :TableField[] = []


  created(){
    this.initializeFields()
  }

  @Watch('formControl')
  handleFormControlChange(){
    this.initializeFields()
  }


  initializeFields(){
    // this.fields = JSON.parse(this.formControl.settings??'[]') as TableField[]
    this.fields = []  as TableField[]
  }


  handleAddColumn() {
    this.fields.push({
      columnTitle: '未命名',
      fieldName: this.buildFieldName(),
      required: false,
      displayOrder: this.fields.length + 2,
      elementType: 'el-input',
      validators: [],
      chooseValues:[]
    })
  }


  buildFieldName() {
    let name = ''
    do {
      name = `field-${newGuid().substring(0, 4)}`
    } while (!this.fields.some(field => field.fieldName === name))
    return name;
  }

}
