








import { Component, Vue } from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import { CustomFormType } from "@/api/appService";
import api from "@/api/index";

@Component({
  name: "CustomFormStandardDetail",
  components: { CustomFormDataTableDetail },
})
export default class CustomFormStandardDetail extends Vue {
  private dataId!: string;

  private hostType!: CustomFormType;

  private isProject = false;

  isReady = false;

  created() {
    this.dataId = this.$route.query.id as string;
    this.hostType = this.$route.query.hostType as CustomFormType;
    this.isProject = true;

    if (this.dataId && this.hostType) {
      this.isReady = true;
    } else {
      this.$message.error("缺少url参数 id 或 hostType，请检查!");
    }
  }

  getData() {
    switch (this.hostType) {
      case CustomFormType.InternalProject:
        return api.internalProject.get({ id: parseInt(this.dataId) });
      case CustomFormType.OrganizationUnit:
        return api.internalProject.get({ id: parseInt(this.dataId) });
    }
    const message = `暂时还未配置${this.hostType}的api`;
    this.$message.error(message);
    throw message;
  }
}
