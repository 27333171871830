



























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  AttachmentHostType,
  CustomFieldDataSourceCreateOrUpdateDto,
  CustomFormFieldChooseValueCreateOrUpdateDto,
  CustomFormFieldCreateOrUpdateDto,
  SelectListItem,
} from "@/api/appService";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import { resolveSelectOptions } from "@/components/CustomForm/common";
import BatchUpload from "@/components/BatchUpload/index.vue";
import ProvinceCityAreaControl from "@/components/ProvinceCityAreaControl/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import ProvinceCityAreaWithoutTownControl from "@/components/ProvinceCityAreaControl/indexWithoutTown.vue";
import TableEditor from "@/components/CustomForm/Design/TableEditor/index.vue";

@Component({
  name: "FormItemControl",
  components: {
    TableEditor,
    ProvinceCityAreaWithoutTownControl,
    ProvinceCityAreaControl,
    BatchUpload,
    SimpleUploadImage,
    Ueditor,
  },
})
export default class FormItemControl extends Vue {
  @Prop({ required: true })
  control!: CustomFormFieldCreateOrUpdateDto;

  @Prop({ required: false, default: false })
  editMode!: boolean;

  testValue: any = "";

  private selectList: SelectListItem[] = [
    { text: "Test1", value: "Test1" },
    { text: "Test2", value: "Test2" },
  ];

  created() {
    this.initSelectOptions();
    if (this.isCheckBox || this.isMultipleSelect) {
      this.testValue = [];
    } else if (this.isProvinceCityAreaTownControl) {
      this.testValue = {
        provinceId: "",
        cityId: "",
        areaId: "",
        townId: "",
      };
    }
    else if (this.isProvinceCityAreaWithoutTownControl) {
      this.testValue = {
        provinceId: "",
        cityId: "",
        areaId: ""
      };
    }
  }

  get isInput() {
    return (
      this.control.elementType === "el-input" ||
      this.control.elementType === "input"
    );
  }

  get isNumber() {
    return (
      this.control.elementType === "el-input-number" ||
      this.control.elementType === "number"
    );
  }

  get isTextarea() {
    return (
      this.control.elementType === "el-textarea" ||
      this.control.elementType === "textarea"
    );
  }

  get isSelect() {
    return ["dropdownList", "dropdownlist", "select"].some(
      (name) => name === this.control.elementType
    );
  }

  get isMultipleSelect() {
    return [
      "Multiple",
      "multiple",
      "MultipleSelect",
      "multipleSelect",
      "multipleselect",
      "multiple-dropdown-list",
      "multipledropdownlist",
      "multipleDropdownList",
      "MultipleDropdownList",
    ].some((name) => name === this.control.elementType);
  }

  get isRadio() {
    return ["el-radio", "radio"].some(
      (name) => name === this.control.elementType
    );
  }

  get isCheckBox() {
    return ["el-checkbox", "checkbox"].some(
      (name) => name === this.control.elementType
    );
  }

  get isSwitch() {
    return ["el-switch", "switch", "Switch"].some(
      (name) => name === this.control.elementType
    );
  }

  get isDate() {
    return ["el-date", "date", "el-date-picker"].some(
      (name) => name === this.control.elementType
    );
  }

  get isProvinceCityAreaTownControl() {
    return [
      "province-city-area-town-control",
      "ProvinceCityAreaTownControl",
      "provinceCityAreaTownControl",
    ].some((name) => name === this.control.elementType);
  }

  get isProvinceCityAreaWithoutTownControl() {
    return [
      "province-city-area-without-town-control",
      "ProvinceCityArea",
      "provinceCityArea",
    ].some((name) => name === this.control.elementType);
  }

  get isDateTime() {
    return ["el-datetime", "datetime"].some(
      (name) => name === this.control.elementType
    );
  }

  get isTime() {
    return ["el-time", "el-time-picker", "time", "time-picker"].some(
      (name) => name === this.control.elementType
    );
  }

  get isSimpleUploadImage() {
    return [
      "simple-upload-image",
      "upload-simple-image",
      "uploadSimpleImage",
      "simpleUploadImage",
    ].some((name) => name === this.control.elementType);
  }

  get isBatchUpload() {
    return ["batch-upload", "upload-batch"].some(
      (name) => name === this.control.elementType
    );
  }

  get isUeditorControl() {
    return ["Ueditor", "editor", "u-editor", "el-editor"].some(
      (name) => name === this.control.elementType
    );
  }

  get uploadHostType() {
    return AttachmentHostType.EditorImage;
  }

  handleRemoveControl($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.$emit("remove-control", this.control);
  }

  handleClickControl() {
    if (this.editMode) {
      this.$emit("control-click", this.control);
      console.log("$emit control-click from form-item-control");
    }
  }

  private initSelectOptions() {
    if (this.control.dataSource) {
      resolveSelectOptions(this.control).then((res) => {
        this.selectList = res;
        this.$forceUpdate();
      });
    }
  }

  @Watch("dataSource", { deep: true })
  handleDataSourceChange(newValue: CustomFieldDataSourceCreateOrUpdateDto) {
    if (newValue) {
      resolveSelectOptions(this.control).then((res) => {
        this.selectList = res;
        this.$forceUpdate();
      });
    }
  }

  get dataSource() {
    return this.control.dataSource;
  }

  @Watch("chooseValues", { deep: true })
  handleChooseValueChange(
    newValue: CustomFormFieldChooseValueCreateOrUpdateDto[]
  ) {
    if (newValue && this.control && this.control.dataSource) {
      resolveSelectOptions(this.control).then((res) => {
        this.selectList = res;
      });
    } else {
      this.selectList = [];
    }
    this.$forceUpdate();
  }

  get chooseValues() {
    return this.control.chooseValues;
  }
}
