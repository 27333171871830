














import {Component, Prop, Vue} from "vue-property-decorator";
import {CustomFormFieldDto} from "@/api/appService";
import {TableField} from "@/components/CustomForm/Design/TableEditor/TableColumnItemPropertyEditor.vue";




@Component({
  name: 'TableEditor',
})
export default class TableEditor extends Vue {

  @Prop({required: true}) field!: CustomFormFieldDto;

  tableData: any[] = [{}, {}]

  tableFields: TableField[] = []

  created() {
    // this.tableFields = this.field.
    // this.tableFields = JSON.parse(this.field.settings ?? '{}') as TableField[]
    this.fields = []  as TableField[]
  }


  handleAddColumn() {
    console.log('handleAddColumn')
  }

  handleRemoveColumn() {
    console.log('handleRemoveColumn', arguments)
  }


}

