import { IControlEditor } from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import { ILayoutItem } from "@/components/CustomForm/common";
import { PropertyValueEditorType } from "@/components/CustomForm/ControlEditor/common";

export default class TitleEditor implements IControlEditor {
  private _layoutItem!: ILayoutItem;

  public title!: ElementProperty;
  public fontSize!: ElementProperty;
  public titleLayout!: ElementProperty;

  constructor(layoutItem: ILayoutItem) {
    this._layoutItem = layoutItem;
    this.title = new ElementProperty(
      "标题内容",
      this._layoutItem.data!,
      undefined
    );
    this.title.propertyValue = this._layoutItem.data.elementContent;
    this.title.isPropertyValueRequired = true;
    this.title.onPropertyValueChange = (newValue) => {
      this._layoutItem.data.elementContent = newValue as string;
    };

    this.titleLayout = new ElementProperty(
      "标题对齐方式",
      undefined,
      undefined
    );
    this.titleLayout.valueEditor = PropertyValueEditorType.Dropdown;
    this.titleLayout.options = [
      { text: "居左对齐", value: "left" },
      { text: "居中对齐", value: "center" },
      { text: "居右对齐", value: "right" },
    ];
    this.titleLayout.defaultValue = "left";
    this.titleLayout.propertyValue = this._layoutItem.data.textAlign ?? "left";
    this.titleLayout.onPropertyValueChange = (newValue) => {
      this._layoutItem.data.textAlign = newValue as string;
    };
  }

  getProperties(): ElementProperty[] {
    return [this.title, this.titleLayout];
  }
}
