





















































































import api from '@/api'
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CustomFieldDataSourceDto, CustomFormDataSourceType, DataDictionaryDto, SelectListItem} from "@/api/appService";
import { required } from '@/core/validator';

export interface TableField {

  /**
   * 显示顺序
   */
  displayOrder: number

  /**
   * 字段名
   */
  fieldName: string
  /**
   * 列标题
   */
  columnTitle: string
  /**
   * 元素类型
   */
  elementType: string
  /**
   * 字段是否为必填项
   */
  required: boolean

  validators?: string[]

  dataSourceType?: CustomFormDataSourceType

  bindValue?: string

  bindDisplayName?: string

  dataSource?: string

  chooseValues: string[]
}

class ElementType {
  control!: string
  displayName!: string


  constructor(control: string, displayName: string) {
    this.control = control;
    this.displayName = displayName;
  }
}

@Component({
  name: 'TableColumnItemPropertyEditor'
})
export default class TableColumnItemPropertyEditor extends Vue {

  @Prop({required: true}) field!: TableField;

  internalField:TableField = {
    chooseValues:[],
    fieldName:'',
    columnTitle:'' ,
    elementType:'',
    required:false,
    displayOrder:0
  }



  showDetail = false;

  customValue = ''


  rules = {
    columnTitle: [
      {required: true, message: '列标题是必填项'},
      {max: 15, message: '列标题字符长度不能超过15个字符'}
    ],
    fieldName: [
      {required: true, message: '列字段名是必填项'},
      {max: 50, message: '列字段名字符长度不能超过50个字符'},
      {pattern: !/^([a-zA-Z]{1}[\d\w_]{0,19})$/, message: '仅能由"字母+数字+横线+下划线"组成'}
    ],
    elementType: [
      {required: true, message: '请选择一个控件类型'}
    ],
    dataSourceType:[
      {validator:required, requiredCondition:()=>['el-select','el-select-multiple'].includes(this.internalField.elementType),message:'下拉控件必须指定一个数据源'},
    ],
    bindValue:[
      {validator:required,requiredCondition:()=>this.internalField.dataSourceType === 'Api',message:'当数据源为api时，此项为必填'}
    ],
    bindDisplayName:[
      {validator:required,requiredCondition:()=>this.internalField.dataSourceType === 'Api',message:'当数据源为api时，此项为必填'}
    ],

  }


  supportElementTypes = [
    new ElementType('el-input', '文本输入'),
    new ElementType('el-input-number', '数值输入'),
    new ElementType('el-select', '下拉列表'),
    new ElementType('el-switch', '开关'),
    new ElementType('el-select-multiple', '下拉多选'),
    new ElementType('el-date', '日期'),
    new ElementType('el-datetime', '日期+时间'),
    new ElementType('el-time', '时间'),
    new ElementType('el-rate', '评分'),
    new ElementType('el-color', '颜色'),
    new ElementType('simple-upload', '单个附件'),

  ]

  dataSourceList: SelectListItem[] = []

  created() {
    if (this.internalField.columnTitle === '未命名') {
      this.showDetail = true;
    }
  }

  @Watch('field')
  handleFieldChanged(newValue:TableField){
    this.internalField = {...newValue}
  }

  handleRemoveCustomValue(index: number){
    this.internalField.chooseValues = this.internalField.chooseValues.slice(index,1);
  }

  handleAppendCustomValue(){
    if(!this.customValue){
      this.$message.error('请输入值')
      return
    }
    if(this.internalField.chooseValues.some(value=>value === this.customValue)){
      this.$message.error('值重复')
      return
    }
    this.internalField.chooseValues.push(this.customValue)
    this.customValue = ''
  }


  handleDataSourceTypeChange() {
    if (!(this.internalField.dataSourceType === 'DataDictionary' || this.internalField.dataSourceType === 'Enum')) {
      return
    }
    if (this.internalField.dataSourceType === 'DataDictionary') {
      this.$store.dispatch('dataDictionary/fetchRoot').then((dataDictionaries: DataDictionaryDto[]) => {
        this.dataSourceList = dataDictionaries.map(item => {
          return {
            text: item.displayName,
            value: item.key
          } as SelectListItem
        })
      })
    } else {
      api.enumService.getAppEnums().then(res => {
        this.dataSourceList = res.map(item => {
          return {
            text: item.text,
            value: item.value
          } as SelectListItem
        })
      })
    }

  }


}
