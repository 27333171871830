import {IControlEditor} from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {PropertyValueEditorType} from "@/components/CustomForm/ControlEditor/common";
import ElementPropertyFactory from "@/components/CustomForm/Design/ControlEditor/ElementPropertyFactory";


export default class TableControlEditor implements IControlEditor {

  public name!: ElementProperty;
  public required!: ElementProperty;
  public tableGroup!:ElementProperty;



  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    this.name = ElementPropertyFactory.createForName(field);
    this.required = ElementPropertyFactory.createForRequired(field)


    const table = new ElementProperty('表格配置',field)
    table.valueEditor = PropertyValueEditorType.Table
    table.isPropertyValueCheckOnRealTime = false
    table.isPropertyValueRequired = true;



    this.tableGroup = ElementPropertyFactory.createGroup('表格配置')
    this.tableGroup.children!.push(table)



  }

  getProperties(): ElementProperty[] {
    return [
      this.name,
      this.required,
      this.tableGroup
    ];
  }

}
