








































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import FormControlList from "@/components/CustomForm/Design/FormControlList.vue";
import FormControl from "@/components/CustomForm/Design/FormControl.vue";
import {
  CustomFormCreateOrUpdateDto,
  CustomFormDataSourceType,
  CustomFormDto,
  CustomFormFieldCreateOrUpdateDto,
  CustomFormFieldDto,
  CustomFormLayoutDetailDto,
  CustomFormType,
} from "@/api/appService";
import { ILayoutItem } from "@/components/CustomForm/common";
import FormItem from "@/components/CustomForm/Design/FormItem.vue";
import api from "@/api";
import $ from "jquery";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import { v4 as uuidv4 } from "uuid";
import FormControlProperties from "@/components/CustomForm/Design/FormControlProperties.vue";
import CustomFormPreview from "@/components/CustomForm/Design/Preview.vue";

@Component({
  name: "CustomFormDesignEditor",
  components: {
    CustomFormPreview,
    FormControlProperties,
    FormItem,
    FormControl,
    FormControlList,
  },
})
export default class CustomFormDesignEditor extends Vue {
  @Prop({ required: false, default: "" })
  formId!: number;

  @Prop({ required: false, default: "" })
  hostId!: number | string;

  @Prop({ required: false, default: CustomFormType.InternalProject })
  hostType!: CustomFormType;

  layoutDetails: ILayoutItem[] = [];

  private loading = true;

  private collapseActiveNames: string[] = ["Basic"];

  private fixedCustomFormFields: CustomFormFieldDto[] = [];
  private sourceFixedCustomFormFields: CustomFormFieldDto[] = [];
  private customFormDto: CustomFormCreateOrUpdateDto = {};
  private allFormControls = new Map<string, CustomFormFieldCreateOrUpdateDto>();

  get defaultHostType() {
    return "";
  }

  async created() {
    this.loadFormFieldsAndData();

    if (this.formId) {
      this.loadFormData();
    }
  }

  loadFormFieldsAndData() {
    api.customFormService
      .getDefaultFields({ formType: this.hostType })
      .then((res) => {
        this.fixedCustomFormFields = res;
        this.sourceFixedCustomFormFields = res.map((item) => {
          return item;
        });

        this.loadFormData();
      });
  }

  loadFormData() {
    if (this.formId) {
      api.customFormService.get({ id: this.formId }).then((res) => {
        this.customFormDto = res;
        this.updateFixedFieldId();

        const rootList = res.layout!.filter((s) => s && !s.parentId);
        this.layoutDetails = this.buildLayoutDetailFromDto(rootList, res);
        this.loading = false;
        this.$nextTick(() => {
          this.resetSortableAndDraggable();
        });
      });
    } else {
      this.loading = false;
      this.$nextTick(() => {
        this.resetSortableAndDraggable();
      });
    }
  }

  updateFixedFieldId() {
    this.customFormDto
      .fields!.filter((s) => s.isDynamic === false)
      .forEach((field) => {
        const inSourceFixed = this.sourceFixedCustomFormFields.filter(
          (s) => s.fieldName === field.fieldName
        );
        if (inSourceFixed && inSourceFixed.length) {
          inSourceFixed[0].id = field.id;
          for (let i = 0; i < this.fixedCustomFormFields.length; i++) {
            const field2 = this.fixedCustomFormFields[i];
            if (field2.fieldName === field.fieldName) {
              field2.id = field.id;
              this.fixedCustomFormFields.splice(i, 1);
              break;
            }
          }
        } else {
          field.isDynamic = true;
        }
      });
  }

  buildLayoutDetailFromDto(
    layouts: CustomFormLayoutDetailDto[],
    dto: CustomFormDto,
    layoutItem?: ILayoutItem
  ) {
    if (!layouts || !layouts.length) {
      return [];
    }
    const result: ILayoutItem[] = [];
    layouts.forEach((item) => {
      const newLayoutItem: ILayoutItem = {
        data: item,
        formControl: undefined,
      };
      let control = dto.fields!.filter((s) => s && s.layoutId === item.id);
      if (item.id && control && control.length) {
        newLayoutItem.formControl = control[0];
        if (newLayoutItem.formControl.id) {
          this.saveFormControlMetaDataToCache(
            newLayoutItem.formControl.id,
            newLayoutItem.formControl,
            "GetDetail"
          );
        }
      }
      const children = dto.layout!.filter((s) => s && s.parentId === item.id);
      newLayoutItem.children = this.buildLayoutDetailFromDto(
        children,
        dto,
        newLayoutItem
      );

      result.push(newLayoutItem);
    });

    return result;
  }

  mounted() {
    this.resetSortableAndDraggable();
  }

  initStandardFormControlDraggable() {
    const that = this as any;

    ($(".custom-form-editor .el-row") as any).sortable({
      revert: "invalid",
      update(ev: Event, ui: any) {
        that.handleFormControlUpdate(ev, ui);
      },
    });

    ($(".form-control-list.standard .form-control") as any).draggable({
      helper: "clone",
      revert: "invalid",
      connectToSortable: ".custom-form-editor .el-row:not(.not-allow-drop)",
    });
  }

  initFixedFields() {
    const that = this as any;

    ($(".custom-form-editor .el-row") as any).sortable({
      revert: "invalid",
      update(ev: Event, ui: any) {
        that.handleFormControlUpdate(ev, ui);
      },
    });

    ($(".form-control-list.fixed .form-control") as any).draggable({
      helper: "clone",
      revert: "invalid",
      connectToSortable: ".custom-form-editor .el-row:not(.not-allow-drop)",
    });
  }

  initRootSortable() {
    (
      $('.form-control-list.basic .form-control[data-name!="el-col"]') as any
    ).draggable({
      connectToSortable: ".custom-form-editor .el-form",
      revert: "invalid",
      helper: "clone",
    });

    const that = this as any;
    ($(".custom-form-editor .el-form") as any).sortable({
      placeholder: "el-row-state-highlight",
      revert: "invalid",
      update(ev: Event, ui: any) {
        console.log("sort updated1111111");
        setTimeout(() => {
          that.resetSortableAndDraggable();
          that.$nextTick(() => {
            that.handleRootSortableUpdate();
          });
        }, 200);
      },
    });
  }

  handleFormControlUpdate(ev: Event, ui: any) {
    const that = this as any;
    const newLayout: ILayoutItem[] = [];
    const $row = $(ui.item).parent();
    const rowId = $row.data("id") as string;
    const rowLayout = this.layoutDetails.filter((s) => s.data.id === rowId)[0]!;

    $row
      .children(":not(.buttons)")
      .each((index: number, element: HTMLElement) => {
        console.log("debugger for row");
        const $element = $(element);

        // 是否是从左边拖拽过来的初始控件
        if ($element.hasClass("el-col")) {
          const id = $element.data("id");
          // 是否是同行元素之间的拖动
          let sameRow = false;
          rowLayout.children!.forEach((item) => {
            if (id == item.data.id) {
              sameRow = true;
            }
          });

          if (sameRow) {
            // 同el-row之间的拖拽
            const id = $element.data("id");
            const item = rowLayout.children!.filter(
              (s) => s.data.id === id
            )[0]!;
            item.data.sequence = index + 1;
            newLayout.push(item);
          } else {
            // 跨el-row之间的拖拽
            const id = $element.data("id");
            const $formControl = $element.find(".form-control-wrap");
            const formControlId = $formControl.data("id");
            const formControlMetadata = this.allFormControls.get(formControlId);

            console.log(
              `drag formControl to other row [${id}],formControlId[${formControlId}], controlMetadata:`,
              formControlMetadata,
              $element
            );

            // 从formControlMetadata存储里拿到拖拽前的数据，并插入到新行中
            const colLayout: ILayoutItem = {
              data: {
                sequence: index + 1,
                id: this.newGuid(),
                parentId: "",
                elementContent: "",
                elementName: "el-col",
                textAlign: "left",
              },
              children: [],
              formControl: formControlMetadata,
            };

            $element.remove();
            newLayout.push(colLayout);

            // 删除元素拖拽前的数据/将拖拽的元素从layoutDetails里删除
            let needRemoveIndex = -1;
            let needRemoveItemIndex = -1;
            this.layoutDetails.forEach((i, indexI) => {
              i.children?.forEach((j, indexJ) => {
                if (j.data.id == id) {
                  needRemoveIndex = indexI;
                  needRemoveItemIndex = indexJ;
                  return;
                }
              });
            });
            if (needRemoveIndex != -1 && needRemoveItemIndex != -1) {
              this.layoutDetails[needRemoveIndex].children?.splice(
                needRemoveItemIndex,
                1
              );
            }
          }
        } else {
          // 从左侧基础库拖拽过来
          const colLayout: ILayoutItem = {
            data: {
              sequence: index + 1,
              id: this.newGuid(),
              parentId: "",
              elementContent: "",
              elementName: "el-col",
              textAlign: "left",
            },
            children: [],
            formControl: that.createFormControlFromElement($element),
          };
          if ($element.hasClass("fixed")) {
            // 如果是从·固定字段·出拖过来的话，做额外处理
            const detail = $element.data("field") as CustomFormFieldDto;
            colLayout.formControl = {
              elementType: detail.elementType,
              required: detail.required,
              fieldName: detail.fieldName,
              fieldDisplayName: detail.fieldDisplayName,
              id: detail.id,
              placeholder: detail.placeholder,
              isDynamic: detail.isDynamic,
              fieldValidators: detail.fieldValidators,
              dataSource: detail.dataSource,
              chooseValues: detail.chooseValues ?? [],
            };
            this.saveFormControlMetaDataToCache(
              detail.id!,
              colLayout.formControl,
              "fromFixed"
            );
            for (let j = 0; j < this.fixedCustomFormFields.length; j++) {
              const field = this.fixedCustomFormFields[j];
              if (field.id === detail.id) {
                this.fixedCustomFormFields.splice(j, 1);
                break;
              }
            }
          }

          $element.remove();
          newLayout.push(colLayout);
        }
      });
    rowLayout.children = newLayout;
    that.$nextTick(() => {
      that.resetSortableAndDraggable();
    });
  }

  resetSortableAndDraggable() {
    this.initStandardFormControlDraggable();
    this.initRootSortable();
    this.initFixedFields();
    this.initEditorFormControlSortableInRows();
  }

  // 跨el-row拖拽
  initEditorFormControlSortableInRows() {
    const that = this as any;
    ($(".custom-form-editor .el-row") as any).sortable({
      revert: "invalid",
      update(ev: Event, ui: any) {
        console.log("row to row update");
        that.handleFormControlUpdate(ev, ui);
        setTimeout(() => {
          that.handleRootSortableUpdate();
          that.$nextTick(() => {
            that.resetSortableAndDraggable();
          });
        }, 200);
      },
    });

    ($(".custom-form-editor .el-row .el-col") as any).draggable({
      helper: "original",
      revert: "invalid",
      connectToSortable: ".custom-form-editor .el-row:not(.not-allow-drop)",
    });
  }

  createFormControlFromElement(
    $element: any
  ): CustomFormFieldCreateOrUpdateDto {
    const formControl: CustomFormFieldCreateOrUpdateDto = {
      isDynamic: true,
      required: false,
      layoutId: "",
      fieldName: $element.data("name") + this.newGuid().substr(0, 6),
      placeholder: "",
      elementType: $element.data("name"),
      fieldDisplayName: $element.data("title"),
      fieldValidators: [],
      id: this.newGuid(),
    };

    if (
      [
        "radio",
        "el-radio",
        "checkbox",
        "el-checkbox",
        "select",
        "el-select",
        "dropdownlist",
        "dropdownList",
        "dropDownList",
        "multiple-dropdown-list",
        "MultipleSelect",
        "multiple-select",
        "auto-complete",
        "autocomplete",
      ].some((s) => s === $element.data("name"))
    ) {
      formControl.chooseValues = [];
    }

    this.saveFormControlMetaDataToCache(
      formControl.id!,
      formControl,
      "fromStandard"
    );

    return formControl;
  }

  // 记录被拖拽出去的元素数据
  private saveFormControlMetaDataToCache(
    formControlId: string,
    formControl: CustomFormFieldCreateOrUpdateDto,
    from: string
  ) {
    console.log(
      `saveFormControlMetaDataToCache [${from}] ${formControlId}`,
      formControl
    );
    this.allFormControls.set(formControlId, formControl);
    console.log(
      `saveFormControlMetaDataToCache111111111111111111`,
      this.allFormControls
    );
  }

  newGuid() {
    return uuidv4();
  }

  handleRootSortableUpdate() {
    const that = this as any;
    const newLayout: ILayoutItem[] = [];
    $(".custom-form-editor > .el-form > *").each(
      (index: number, element: HTMLElement) => {
        const $element = $(element);
        if ($element.hasClass("form-control")) {
          const elementType = $element.data("name");
          const item: ILayoutItem = {
            children: [],
            data: {
              id: that.newGuid(),
              sequence: index + 1,
              elementName: elementType,
              elementContent: $element.data("default-text"),
              parentId: "",
              textAlign: "left",
            },
          };
          newLayout.push(item);
          $element.remove();
        } else if ($element.hasClass("editor-first-drag-prompt") === false) {
          const id = $element.data("id");
          const filterResult = that.layoutDetails.filter(
            (s: any) => s.data.id === id
          );
          if (filterResult.length) {
            let item = filterResult[0];
            item.data.sequence = index + 1;
            newLayout.push(item);
          }
        }
      }
    );
    that.layoutDetails = newLayout;
    that.$nextTick(() => {
      that.resetSortableAndDraggable();
    });
  }

  handleControlClick(control: CustomFormFieldCreateOrUpdateDto) {
    (this.$refs.formControlProperties as any).formControl = control;
    (this.$refs.formControlProperties as any).show = true;
  }

  handleRemoveBasicControl(item: ILayoutItem) {
    for (let i = 0; i < this.layoutDetails.length; i++) {
      const currentItem = this.layoutDetails[i];
      if (currentItem.data.id === item.data.id) {
        this.layoutDetails.splice(i, 1);
        break;
      }
    }
  }

  handleRemoveControl(control: CustomFormFieldCreateOrUpdateDto) {
    console.log("handle remove control at DataItemDiff.vue");
    let hasFound = false;
    let formControl: CustomFormFieldCreateOrUpdateDto;
    for (let i = 0; i < this.layoutDetails.length; i++) {
      const rowItem = this.layoutDetails[i];
      for (let j = 0; j < (rowItem.children?.length ?? 0); j++) {
        const colItem = rowItem.children![j]!;
        formControl = colItem.formControl!;
        if (formControl.id === control!.id) {
          rowItem.children!.splice(j, 1);
          hasFound = true;
          break;
        }
      }
      if (hasFound) {
        break;
      }
    }

    if (formControl!.isDynamic === false) {
      const sourceField = this.sourceFixedCustomFormFields.filter(
        (s) => s.id === formControl.id
      )[0];
      this.fixedCustomFormFields.push(sourceField);
      this.$nextTick(() => {
        this.resetSortableAndDraggable();
      });
    }
  }

  public save() {
    // check fixed fields
    const notInEditorFields = this.fixedCustomFormFields.filter(
      (s) => s && s.required
    );
    if (notInEditorFields && notInEditorFields.length) {
      const fieldsNames = notInEditorFields.map(
        (field) => field.fieldDisplayName
      );
      this.$message.error(
        `固定字段中,[${fieldsNames.join(
          ","
        )}]必须放置到编辑器中，因为他们是必填项`
      );
      return;
    }

    const layoutList: CustomFormLayoutDetailDto[] = [];
    const fields: CustomFormFieldCreateOrUpdateDto[] = [];

    this.buildRequestData(this.layoutDetails, layoutList, fields);

    if (!this.checkFields(fields)) {
      return;
    }

    this.customFormDto.fields = fields;
    this.customFormDto.layout = layoutList;
    if (!this.formId) {
      this.customFormDto.hostId = this.hostId.toString();
      this.customFormDto.hostType = this.hostType;
      this.customFormDto.title = this.hostType.toString();
    }

    this.$emit("save-start");
    if (this.formId || this.customFormDto.id) {
      api.customFormService
        .update({ body: this.customFormDto })
        .then((res) => {
          this.$message.success("保存成功");

          this.$router.back();
          this.$emit("save-end");
        })
        .catch((res) => {
          this.$emit("save-end");
        });
    } else {
      api.customFormService
        .create({ body: this.customFormDto })
        .then((res) => {
          this.$message.success("保存成功");
          this.$router.back();
          // this.customFormDto.id = res.id;
          this.$emit("save-end");
          this.$router.push({
            name: "customFormDesign",
            query: {
              formId: String(res.id),
              hostType: this.hostType,
            },
          });
        })
        .catch((res) => {
          this.$emit("save-end");
        });
    }
  }

  private checkFields(fields: CustomFormFieldCreateOrUpdateDto[]) {
    if (fields.length === 0) {
      this.$message.error("编辑器中未添加控件");
      return false;
    }
    for (let i = 0; i < fields.length; i++) {
      for (let j = i + 1; j < fields.length; j++) {
        if (fields[i].fieldName === fields[j].fieldName) {
          this.$message(
            `存在相同的两个字段名,字段名:${fields[i].fieldName},明细:[${fields[i].fieldDisplayName}]与[${fields[j].fieldDisplayName}]的字段名相同`
          );
          return false;
        }
      }
    }

    return true;
  }

  private buildRequestData(
    layoutItems: ILayoutItem[],
    layoutList: CustomFormLayoutDetailDto[],
    fields: CustomFormFieldCreateOrUpdateDto[],
    parentLayoutItem?: ILayoutItem
  ) {
    if (!layoutItems || !layoutItems.length) {
      return;
    }

    layoutItems.forEach((item: ILayoutItem) => {
      let hasData = false;
      if (item.formControl || item.children || item.children!.length) {
        hasData = true;
      }
      if (hasData) {
        if (!item.data.id) {
          item.data.id = this.newGuid();
        }
        const currentLayout = { ...item.data };
        if (parentLayoutItem) {
          currentLayout.parentId = parentLayoutItem.data.id;
        } else {
          currentLayout.parentId = undefined;
        }

        layoutList.push(currentLayout);

        if (item.formControl && item.formControl.elementType) {
          const field = { ...item.formControl };
          field.layoutId = currentLayout.id;
          if (field.dataSource) {
            field.dataSource!.fieldId = field.id;
            if (!field.dataSource.id) {
              field.dataSource.id = this.newGuid();
            }
          }

          fields.push(field);
        }
        if (item.children) {
          this.buildRequestData(item.children!, layoutList, fields, item);
        }
      }
    });
  }

  handlePreview() {
    (this.$refs.customFormPreview as any).layoutDetails = this.layoutDetails;
    (this.$refs.customFormPreview as any).show = true;
  }
}
