import {
  CustomFormFieldCreateOrUpdateDto,
  CustomFormLayoutDetailCreateOrUpdateDto,
  SelectListItem,
} from "@/api/appService";
import { PropertyValueEditorType } from "@/components/CustomForm/ControlEditor/common";

type onValueChange = (newValue: string | undefined) => void;
type propertyValueValidate = (value: string | undefined) => string[];

export default class ElementProperty {
  private _propertyValue?: string;
  private _layoutItem?: CustomFormLayoutDetailCreateOrUpdateDto;
  private _formControl?: CustomFormFieldCreateOrUpdateDto;
  private _options: SelectListItem[] = [];
  private _valueCheckErrors: string[] = [];

  propertyName!: string;
  defaultValue?: string;
  show = true;
  valueEditor!: PropertyValueEditorType;
  children?: ElementProperty[];
  readonly = false;
  isPropertyValueRequired = false;
  isPropertyValueCheckOnRealTime = true;

  onPropertyValueChange?: onValueChange;
  propertyValueValidate?: propertyValueValidate;

  constructor(
    propertyName: string,
    layoutItem?: CustomFormLayoutDetailCreateOrUpdateDto,
    formControl?: CustomFormFieldCreateOrUpdateDto
  ) {
    this.propertyName = propertyName;
    this.valueEditor = PropertyValueEditorType.Input;
    this._layoutItem = layoutItem ?? {};
    this._formControl = formControl ?? {};

    if (this._layoutItem) {
      this._propertyValue = this._layoutItem.elementContent;
    }

    if (this._formControl) {
      if (!this._formControl.chooseValues) {
        this._formControl.chooseValues = [];
      }
    }
  }

  get formControl(): CustomFormFieldCreateOrUpdateDto | undefined {
    return this._formControl;
  }

  get layoutItem(): CustomFormLayoutDetailCreateOrUpdateDto | undefined {
    return this._layoutItem;
  }

  get valueCheckErrors(): string[] {
    return this._valueCheckErrors;
  }

  get propertyValue(): string | undefined {
    return this._propertyValue;
  }

  set propertyValue(newValue: string | undefined) {
    console.log(`${this.propertyName} setPropertyValue:${newValue}`);
    if (typeof newValue === "boolean") {
      if (this.onPropertyValueChange) {
        this.onPropertyValueChange(newValue);
      }
    } else {
      if (newValue !== this._propertyValue) {
        if (this.onPropertyValueChange) {
          if (this.isPropertyValueCheckOnRealTime) {
            const checkResult = this.checkNewValue(newValue);
            if (checkResult && checkResult.length) {
              this._valueCheckErrors = checkResult;
            } else {
              this._valueCheckErrors = [];
              this.onPropertyValueChange(newValue);
            }
          } else {
            this.onPropertyValueChange(newValue);
          }
        }
      }
    }

    this._propertyValue = newValue as string;
  }

  get options(): SelectListItem[] {
    return this._options;
  }
  set options(value: SelectListItem[]) {
    this._options = value;
  }

  private checkNewValue(newValue: string | undefined) {
    let result: string[] = [];
    if (!newValue && this.isPropertyValueRequired) {
      result.push("该属性值是必须的");
    }
    if (this.propertyValueValidate) {
      result = [...this.propertyValueValidate(newValue)];
    }

    return result;
  }

  public checkPropertyValue(): string[] {
    let result: string[] = [];
    if (!this.propertyValue && this.isPropertyValueRequired) {
      result.push("该属性值是必须的");
    }
    if (this.propertyValueValidate) {
      result = [...this.propertyValueValidate(this.propertyValue)];
    }

    return result;
  }
}
