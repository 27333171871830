import AbstractControlEditor from "@/components/CustomForm/Design/ControlEditor/AbstractControlEditor";
import {
  CustomFieldDataSourceCreateOrUpdateDto,
  CustomFormDataSourceType,
  CustomFormFieldCreateOrUpdateDto,
  DataDictionaryDto,
  SelectListItem,
} from "@/api/appService";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import {
  newGuid,
  PropertyValueEditorType,
} from "@/components/CustomForm/ControlEditor/common";
import api from "@/api";
import { Vue } from "vue-property-decorator";

export default class AbstractSelectControlEditor extends AbstractControlEditor {
  dataSource!: ElementProperty;

  private typeProperty!: ElementProperty;
  private sourceProperty!: ElementProperty;
  private bindDisplayProperty!: ElementProperty;
  private bindValueProperty!: ElementProperty;

  dataDictionaryList: DataDictionaryDto[] = [];
  enumList: SelectListItem[] = [];

  private defaultDataSource: CustomFieldDataSourceCreateOrUpdateDto = {
    dataSource: "",
    bindValue: "",
    bindDisplayName: "",
    dataSourceType: CustomFormDataSourceType.DataDictionary,
    id: newGuid(),
  };

  constructor(field: CustomFormFieldCreateOrUpdateDto) {
    super(field);
    if (!this.field.dataSource) {
      Vue.set(this.field, "dataSource", this.defaultDataSource);
    } else {
      Vue.set(this.field, "dataSource", this.field.dataSource);
    }

    if (this.field.chooseValues && this.field.chooseValues.length) {
      const values = this.field.chooseValues;
      Vue.set(this.field, "chooseValues", []);
      values.forEach((value) => {
        this.field.chooseValues!.push(value);
      });
    } else {
      Vue.set(this.field, "chooseValues", []);
    }

    this.dataSource = new ElementProperty("数据源", undefined, this.field);
    this.dataSource.valueEditor = PropertyValueEditorType.Group;
    this.dataSource.children = [];

    this.typeProperty = new ElementProperty(
      "数据源类型",
      undefined,
      this.field
    );
    this.typeProperty.valueEditor = PropertyValueEditorType.Dropdown;
    this.typeProperty.options = [
      { text: "枚举", value: "Enum" },
      { text: "数据字典", value: "DataDictionary" },
      { text: "自定义值", value: "CustomValues" },
      { text: "API", value: "Api" },
    ];
    this.typeProperty.defaultValue = "DataDictionary";

    this.sourceProperty = new ElementProperty("来源", undefined, this.field);
    this.sourceProperty.valueEditor = PropertyValueEditorType.Input;
    this.sourceProperty.isPropertyValueRequired = true;

    this.bindDisplayProperty = new ElementProperty(
      "绑定要显示的名称",
      undefined,
      this.field
    );
    this.bindDisplayProperty.valueEditor = PropertyValueEditorType.Input;

    this.bindValueProperty = new ElementProperty(
      "绑定要使用的值",
      undefined,
      this.field
    );
    this.bindValueProperty.valueEditor = PropertyValueEditorType.Input;

    this.dataSource.children.push(this.typeProperty);
    this.dataSource.children.push(this.sourceProperty);
    this.dataSource.children.push(this.bindDisplayProperty);
    this.dataSource.children.push(this.bindValueProperty);

    this.sourceProperty.onPropertyValueChange = (newValue) => {
      console.log("sourceProperty value changed:" + newValue);
      this.field.dataSource!.dataSource = newValue;
    };
    this.bindDisplayProperty.onPropertyValueChange = (newValue) => {
      this.field.dataSource!.bindDisplayName = newValue;
    };

    this.bindValueProperty.onPropertyValueChange = (newValue) => {
      this.field.dataSource!.bindValue = newValue;
    };

    this.typeProperty.onPropertyValueChange = (newValue) => {
      this.handleTypeValueChange(newValue);
    };

    if (!this.field.dataSource!.dataSourceType) {
      this.typeProperty.propertyValue = this.typeProperty.defaultValue;
    } else {
      this.typeProperty.propertyValue = this.field.dataSource!.dataSourceType;
    }

    this.sourceProperty.propertyValue = this.field.dataSource!.dataSource;
    this.bindDisplayProperty.propertyValue =
      this.field.dataSource!.bindDisplayName;
    this.bindValueProperty.propertyValue = this.field.dataSource!.bindValue;
  }

  handleTypeValueChange(newValue: string | undefined) {
    switch (newValue) {
      case "Api":
        this.field.dataSource!.dataSourceType =
          CustomFormDataSourceType.Api;
        break;
      // case 'API':
      //   this.field.dataSource!.dataSourceType = CustomFormDataSourceType.Api;
      //   break;
      case "CustomValues":
        this.field.dataSource!.dataSourceType =
          CustomFormDataSourceType.CustomValues;
        break;
      case "DataDictionary":
        this.field.dataSource!.dataSourceType =
          CustomFormDataSourceType.DataDictionary;
        break;
      case "Enum":
        this.field.dataSource!.dataSourceType = CustomFormDataSourceType.Enum;
        break;
    }

    if (newValue === "API" || newValue === "Api") {
      this.sourceProperty.valueEditor = PropertyValueEditorType.Input;
    } else if (newValue === "CustomValues") {
      this.sourceProperty.valueEditor = PropertyValueEditorType.CustomValues;
    } else {
      this.sourceProperty.valueEditor = PropertyValueEditorType.Dropdown;
    }

    switch (newValue) {
      case "Enum":
        this.bindDisplayProperty.propertyValue = "text";
        this.bindDisplayProperty.show = false;

        this.bindValueProperty.propertyValue = "value";
        this.bindValueProperty.show = false;

        this.sourceProperty.show = true;
        this.getEnumList().then((res) => {
          this.sourceProperty.options = res;
        });
        break;
      case "Api":
        // this.bindDisplayProperty.propertyValue = "";
        this.bindDisplayProperty.show = true;

        // this.bindValueProperty.propertyValue = "";
        this.bindValueProperty.show = true;

        this.sourceProperty.show = true;
        this.sourceProperty.options = [];
        break;
      case "DataDictionary":
        this.bindDisplayProperty.propertyValue = "text";
        this.bindDisplayProperty.show = false;

        this.bindValueProperty.propertyValue = "value";
        this.bindValueProperty.show = false;

        this.sourceProperty.show = true;
        this.getDataDictionaryList().then((res: DataDictionaryDto[]) => {
          this.sourceProperty.options = res.map((item) => {
            return { text: item.displayName, value: item.key };
          });
        });
        break;
      case "CustomValues":
        this.bindDisplayProperty.propertyValue = "value";
        this.bindDisplayProperty.show = false;

        this.bindValueProperty.propertyValue = "value";
        this.bindValueProperty.show = false;

        this.sourceProperty.show = true;
        this.sourceProperty.propertyValue = "CustomValues";
        this.sourceProperty.options = [];
        this.sourceProperty.propertyValue = "CustomValues";
        this.sourceProperty.isPropertyValueRequired = true;
    }

    this.sourceProperty.propertyValue = "";
  }

  getEnumList() {
    return new Promise<SelectListItem[]>((resolve, reject) => {
      if (this.enumList && this.enumList.length) {
        resolve(this.enumList);
      } else {
        api.enumService.getAppEnums().then((res) => {
          this.enumList = res;
          resolve(this.enumList);
        });
      }
    });
  }

  getDataDictionaryList() {

    return new Promise<DataDictionaryDto[]>((resolve, reject) => {
      if (this.dataDictionaryList && this.dataDictionaryList.length) {
        resolve(this.dataDictionaryList);
      } else {
        api.dataDictionary.getAllRoot().then((res) => {
          this.dataDictionaryList = res;
          resolve(this.dataDictionaryList);
        });
      }
    });
  }

  getProperties(): ElementProperty[] {
    return [...super.getProperties(), this.dataSource];
  }
}
