






import { Component, Vue } from "vue-property-decorator";

@Component({ name: "FormControlList" })
export default class FormControlList extends Vue {}
