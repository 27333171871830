












































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ILayoutItem } from "@/components/CustomForm/common";
import FormItemControl from "@/components/CustomForm/Design/FormItemControl.vue";
import { CustomFormFieldCreateOrUpdateDto } from "@/api/appService";
import FormControlProperties from "@/components/CustomForm/Design/FormControlProperties.vue";

@Component({
  name: "FormItem",
  components: { FormControlProperties, FormItemControl },
})
export default class FormItem extends Vue {
  @Prop({ required: true })
  item!: ILayoutItem;

  @Prop({ required: true })
  index!: number;

  @Prop({ required: true })
  items!: ILayoutItem[];

  @Prop({ default: false, required: false })
  editMode!: boolean;

  created() {
    if (this.editMode) {
      if (this.item.data.elementName === "el-divider") {
        this.initAttributeProperty("content-position");
      }
    }
  }

  initAttributeProperty(propertyName: string) {
    let value = "";
    let attributes = this.item.data.elementAttributes as any;
    if (typeof attributes === "object") {
      value = attributes[propertyName];
    } else {
      this.$set(this.item.data, "elementAttributes", {});
      value = "";
    }
    this.$set(this.item.data.elementAttributes as any, propertyName, value);
  }

  handleRemoveControl(control: CustomFormFieldCreateOrUpdateDto) {
    console.log("handleRemoveControl at formItem.vue");
    this.$emit("remove-control", control);
  }

  handleRemoveBasicControl(item: ILayoutItem) {
    console.log("handleRemoveBasicControl", item);
    this.$emit("remove-basic-control", item);
  }

  handleControlClick(control: CustomFormFieldCreateOrUpdateDto) {
    this.$emit("control-click", control);
    console.log("$emit control-click from form-item");
  }

  handleBasicControlClick(item: ILayoutItem) {
    (this.$refs.formControlProperties as any).show = true;
    (this.$refs.formControlProperties as any).formControl = { data: {} };
    (this.$refs.formControlProperties as any).isTitleRow = true;
    (this.$refs.formControlProperties as any).layoutItem = item;
  }

  calcSpanSize() {
    const length = this.items.length;
    let result = 0;
    if (length === 0) {
      return result;
    }
    if (24 % length === 0) {
      result = 24 / length;
    } else {
      const normalSize = parseInt((24 / length).toString());
      if (this.index + 1 === length) {
        result = 24 - (length - 1) * normalSize;
      } else {
        result = normalSize;
      }
    }
    console.log(
      `calcSpanSize[index:${this.index}][length:${length}],[result:${result}]`
    );
    return result;
  }

  getAttribute(attrName: string, item: ILayoutItem) {
    const attributes = item.data.elementAttributes as any;

    let result = "";
    if (attributes) {
      result = attributes[attrName] ?? "";
    } else {
      result = "";
    }
    this.$set(item.data.elementAttributes as any, attrName, result);
    return result;
  }
}
