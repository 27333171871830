import { CustomFormFieldCreateOrUpdateDto } from "@/api/appService";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";
import { IControlEditor } from "@/components/CustomForm/Design/ControlEditor/IControlEditor";
import { PropertyValueEditorType } from "@/components/CustomForm/ControlEditor/common";
import ElementPropertyFactory from "@/components/CustomForm/Design/ControlEditor/ElementPropertyFactory";

export default class AbstractControlEditor implements IControlEditor {
  private _field!: CustomFormFieldCreateOrUpdateDto;

  public name!: ElementProperty;
  public formLabel!: ElementProperty;
  public required!: ElementProperty;
  public validators!: ElementProperty;

  constructor(field: CustomFormFieldCreateOrUpdateDto) {

    this.name = ElementPropertyFactory.createForName(field);
    this.formLabel = ElementPropertyFactory.createForTitle(field)
    this.required = ElementPropertyFactory.createForRequired(field);



    this._field = field;

    this.validators = new ElementProperty("数据验证器", undefined, field);
    this.validators.valueEditor = PropertyValueEditorType.Validators;
  }

  protected get field() {
    return this._field;
  }

  getProperties(): ElementProperty[] {
    return [this.name, this.formLabel, this.required];
  }
}
